import { SVGProps, useId } from 'react';

export const IconBg2 = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 18 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 552 176" fill="none" {...props}>
      <g opacity="0.6" clipPath={`url(#${gradientIds[0]})`}>
        <path
          d="M5.48929 30.5107C4.52425 29.5457 4.52426 27.981 5.4893 27.016C6.45433 26.051 8.01897 26.051 8.98401 27.016L13.7495 31.7815C14.7146 32.7465 14.7146 34.3112 13.7495 35.2762C12.7845 36.2413 11.2198 36.2413 10.2548 35.2762L5.48929 30.5107Z"
          fill="currentColor"
        />
        <path
          d="M10.2505 16.2185C9.28544 15.2535 9.28544 13.6888 10.2505 12.7238C11.2155 11.7587 12.7802 11.7587 13.7452 12.7238L18.5107 17.4893C19.4757 18.4543 19.4757 20.019 18.5107 20.984C17.5457 21.949 15.981 21.949 15.016 20.984L10.2505 16.2185Z"
          fill="currentColor"
        />
        <path
          d="M0.723779 25.7452C-0.24126 24.7802 -0.24126 23.2155 0.723779 22.2505C1.68882 21.2854 3.25345 21.2854 4.21849 22.2505C5.18353 23.2155 5.18353 24.7802 4.21849 25.7452C3.25345 26.7102 1.68882 26.7102 0.723779 25.7452Z"
          fill="currentColor"
        />
        <path
          d="M19.7815 25.7495C18.8165 24.7845 18.8165 23.2198 19.7815 22.2548C20.7465 21.2898 22.3112 21.2898 23.2762 22.2548C24.2413 23.2198 24.2413 24.7845 23.2762 25.7495C22.3112 26.7146 20.7465 26.7146 19.7815 25.7495Z"
          fill="currentColor"
        />
        <path
          d="M5.4893 20.9797C4.52426 20.0146 4.52426 18.45 5.4893 17.485C6.45433 16.5199 8.01897 16.5199 8.98401 17.485L18.515 27.016C19.4801 27.981 19.4801 29.5457 18.515 30.5107C17.55 31.4757 15.9854 31.4757 15.0203 30.5107L5.4893 20.9797Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5" clipPath={`url(#${gradientIds[1]})`}>
        <path
          d="M3.25 68.756C3.25 70.1367 4.36929 71.256 5.75 71.256H6.428C6.94317 71.256 7.40558 71.5721 7.59266 72.0521L10.4559 79.3984C10.5985 79.7644 10.9511 80.0053 11.3439 80.0053C12.3966 80.0053 13.25 79.1519 13.25 78.0992V75.0061C13.25 74.3158 13.8096 73.7561 14.5 73.7561H15.746C18.0184 73.7561 19.7686 71.7511 19.4616 69.4995L18.7791 64.4934C18.5258 62.6353 16.9388 61.25 15.0635 61.25H5.75C4.36929 61.25 3.25 62.3693 3.25 63.75V68.756Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M5 112.999C5 111.895 5.89543 110.999 7 110.999H7.5424C7.95454 110.999 8.32446 110.747 8.47413 110.363L10.7647 104.486C10.8788 104.193 11.1609 104 11.4751 104C12.3173 104 13 104.683 13 105.525V107.999C13 108.552 13.4477 108.999 14 108.999H15.0011C16.819 108.999 18.2192 110.603 17.9736 112.405L17.4276 116.41C17.2249 117.896 15.9553 119.004 14.4551 119.004H7C5.89543 119.004 5 118.109 5 117.004V112.999Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M13.0617 144.861C13.1735 144.083 12.1657 143.679 11.709 144.318L6.89179 151.064C6.53731 151.561 6.89216 152.25 7.50214 152.25L11.3537 152.25L10.9386 155.139C10.8268 155.917 11.8346 156.321 12.2913 155.682L17.1082 148.937C17.4627 148.44 17.1078 147.751 16.4978 147.751L12.6465 147.751L13.0617 144.861Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.975 35.5C53.975 34.3954 54.8705 33.5 55.975 33.5H56.025C57.1296 33.5 58.025 34.3954 58.025 35.5V52.5C58.025 53.6046 57.1296 54.5 56.025 54.5H55.975C54.8705 54.5 53.975 53.6046 53.975 52.5V35.5ZM62.0751 40C62.0751 38.8954 62.9705 38 64.0751 38H64.1251C65.2296 38 66.1251 38.8954 66.1251 40V52.5C66.1251 53.6046 65.2296 54.5 64.1251 54.5H64.0751C62.9705 54.5 62.0751 53.6046 62.0751 52.5V40ZM47.875 42.5C46.7704 42.5 45.875 43.3954 45.875 44.5V52.5C45.875 53.6046 46.7704 54.5 47.875 54.5H47.925C49.0296 54.5 49.925 53.6046 49.925 52.5V44.5C49.925 43.3954 49.0296 42.5 47.925 42.5H47.875Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M63.5 82.9931C63.5 84.2469 62.8734 85.4178 61.8301 86.1132L57.875 88.75C57.8563 88.7595 57.843 88.7694 57.8291 88.7797C57.8189 88.7873 57.8084 88.7951 57.7953 88.8031L57.7037 88.8642C57.3531 89.1184 57.125 89.5314 57.125 89.9976L57.1242 89.9988L57.125 90C57.125 90.4662 57.3531 90.8792 57.7037 91.1334L57.7953 91.1944C57.8084 91.2024 57.8188 91.2102 57.829 91.2178C57.8429 91.2281 57.8563 91.238 57.875 91.2476L61.8301 93.8843C62.8734 94.5798 63.5 95.7507 63.5 97.0045V97.4976C63.5 98.8783 62.3807 99.9976 61 99.9976L51 99.9976C49.6193 99.9976 48.5 98.8783 48.5 97.4976V97.0045C48.5 95.7507 49.1266 94.5798 50.1699 93.8843L54.125 91.2476C54.1437 91.238 54.1571 91.2281 54.1709 91.2178C54.1811 91.2102 54.1916 91.2024 54.2047 91.1945L54.2963 91.1334C54.6469 90.8792 54.875 90.4662 54.875 90L54.8758 89.9988L54.875 89.9976C54.875 89.5313 54.6469 89.1184 54.2963 88.8642L54.2047 88.8031C54.1916 88.7951 54.1811 88.7874 54.171 88.7798C54.1571 88.7695 54.1437 88.7595 54.125 88.75L50.1699 86.1133C49.1266 85.4178 48.5 84.2469 48.5 82.9931V82.5C48.5 81.1193 49.6193 80 51 80H61C62.3807 80 63.5 81.1193 63.5 82.5V82.9931ZM60.1937 84.5L60.5821 84.2411C60.9993 83.9629 61.25 83.4946 61.25 82.9931V82.5C61.25 82.3619 61.1381 82.25 61 82.25L51 82.25C50.8619 82.25 50.75 82.3619 50.75 82.5V82.9931C50.75 83.4946 51.0007 83.9629 51.4179 84.2411L51.8063 84.5H60.1937ZM60.1412 95.4625H51.8588L56 92.7017L60.1412 95.4625Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M51.6 125C49.6118 125 48 126.612 48 128.6V130H52.9584C53.6535 130 54.2786 130.423 54.5368 131.069L54.7785 131.673C54.9783 132.172 55.4621 132.5 56 132.5C56.5379 132.5 57.0217 132.172 57.2215 131.673L57.4632 131.069C57.7214 130.423 58.3465 130 59.0416 130H64V128.6C64 126.612 62.3882 125 60.4 125H51.6Z"
          fill="currentColor"
        />
        <path
          d="M64 131.8H59.1093L58.8927 132.342C58.4196 133.524 57.274 134.3 56 134.3C54.726 134.3 53.5804 133.524 53.1073 132.342L52.8907 131.8H48V137.2C48 138.194 48.8059 139 49.8 139H62.2C63.1941 139 64 138.194 64 137.2V131.8Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[2]})`}>
        <path
          d="M52.7446 173.255C52.2621 172.773 52.2621 171.991 52.7446 171.508C53.2272 171.025 54.0095 171.025 54.492 171.508L56.8748 173.891C57.3573 174.373 57.3573 175.156 56.8748 175.638C56.3922 176.121 55.6099 176.121 55.1274 175.638L52.7446 173.255Z"
          fill="currentColor"
        />
        <path
          d="M55.1252 166.109C54.6427 165.627 54.6427 164.844 55.1252 164.362C55.6078 163.879 56.3901 163.879 56.8726 164.362L59.2554 166.745C59.7379 167.227 59.7379 168.009 59.2554 168.492C58.7728 168.975 57.9905 168.975 57.508 168.492L55.1252 166.109Z"
          fill="currentColor"
        />
        <path
          d="M50.3619 170.873C49.8794 170.39 49.8794 169.608 50.3619 169.125C50.8444 168.643 51.6267 168.643 52.1092 169.125C52.5918 169.608 52.5918 170.39 52.1092 170.873C51.6267 171.355 50.8444 171.355 50.3619 170.873Z"
          fill="currentColor"
        />
        <path
          d="M59.8908 170.875C59.4082 170.392 59.4082 169.61 59.8908 169.127C60.3733 168.645 61.1556 168.645 61.6381 169.127C62.1206 169.61 62.1206 170.392 61.6381 170.875C61.1556 171.357 60.3733 171.357 59.8908 170.875Z"
          fill="currentColor"
        />
        <path
          d="M52.7446 168.49C52.2621 168.007 52.2621 167.225 52.7446 166.742C53.2272 166.26 54.0095 166.26 54.492 166.742L59.2575 171.508C59.74 171.991 59.74 172.773 59.2575 173.255C58.775 173.738 57.9927 173.738 57.5102 173.255L52.7446 168.49Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M89.5 25.4992C89.5 23.8423 90.8431 22.4992 92.5 22.4992H93.3136C93.9318 22.4992 94.4867 22.1199 94.7112 21.5439L98.1471 12.7284C98.3182 12.2892 98.7413 12 99.2127 12C100.476 12 101.5 13.0241 101.5 14.2873V17.9991C101.5 18.8275 102.172 19.4991 103 19.4991H104.502C107.229 19.4991 109.329 21.9051 108.96 24.607L108.141 30.6143C107.837 32.844 105.933 34.5064 103.683 34.5064H92.5C90.8431 34.5064 89.5 33.1633 89.5 31.5064V25.4992Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M101.77 61.4345C101.956 60.1381 100.276 59.4645 99.515 60.5303L91.4863 71.7737C90.8955 72.601 91.4869 73.7501 92.5036 73.7501L98.9229 73.7501L98.2309 78.5656C98.0446 79.862 99.7243 80.5357 100.485 79.4698L108.514 68.2277C109.104 67.4003 108.513 66.2513 107.496 66.2513L101.077 66.2512L101.77 61.4345Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4" clipPath={`url(#${gradientIds[3]})`}>
        <path
          d="M93 111.005C93 112.109 93.8954 113.005 95 113.005H95.5424C95.9545 113.005 96.3245 113.258 96.4741 113.642L98.7647 119.519C98.8788 119.811 99.1609 120.004 99.4751 120.004C100.317 120.004 101 119.322 101 118.479V116.005C101 115.453 101.448 115.005 102 115.005H102.997C104.815 115.005 106.215 113.401 105.969 111.6L105.423 107.595C105.221 106.108 103.951 105 102.451 105H95C93.8954 105 93 105.895 93 107V111.005Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.5 145.796C104.5 146.548 104.124 147.251 103.498 147.668L101.125 149.25C101.114 149.256 101.106 149.262 101.097 149.268C101.091 149.272 101.085 149.277 101.077 149.282L101.022 149.319C100.812 149.471 100.675 149.719 100.675 149.999L100.675 149.999L100.675 150C100.675 150.28 100.812 150.528 101.022 150.68L101.077 150.717C101.085 150.721 101.091 150.726 101.097 150.731C101.106 150.737 101.114 150.743 101.125 150.749L103.498 152.331C104.124 152.748 104.5 153.45 104.5 154.203V154.499C104.5 155.327 103.828 155.999 103 155.999L97 155.999C96.1716 155.999 95.5 155.327 95.5 154.499V154.203C95.5 153.45 95.876 152.748 96.5019 152.331L98.875 150.749C98.8862 150.743 98.8942 150.737 98.9026 150.731C98.9087 150.726 98.915 150.721 98.9228 150.717L98.9778 150.68C99.1882 150.527 99.325 150.28 99.325 150L99.3255 149.999L99.325 149.999C99.325 149.719 99.1882 149.471 98.9778 149.319L98.9228 149.282C98.915 149.277 98.9087 149.272 98.9026 149.268C98.8942 149.262 98.8862 149.256 98.875 149.25L96.5019 147.668C95.876 147.251 95.5 146.548 95.5 145.796V145.5C95.5 144.672 96.1716 144 97 144H103C103.828 144 104.5 144.672 104.5 145.5V145.796ZM102.516 146.7L102.749 146.545C103 146.378 103.15 146.097 103.15 145.796V145.5C103.15 145.417 103.083 145.35 103 145.35L97 145.35C96.9172 145.35 96.85 145.417 96.85 145.5V145.796C96.85 146.097 97.0004 146.378 97.2508 146.545L97.4838 146.7H102.516ZM102.485 153.277H97.5153L100 151.621L102.485 153.277Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6" clipPath={`url(#${gradientIds[4]})`}>
        <path
          d="M137.4 33.5C134.418 33.5 132 35.9177 132 38.9V41H139.438C140.48 41 141.418 41.6348 141.805 42.603L142.168 43.5095C142.467 44.2587 143.193 44.75 144 44.75C144.807 44.75 145.533 44.2587 145.832 43.5096L146.195 42.603C146.582 41.6348 147.52 41 148.562 41H156V38.9C156 35.9177 153.582 33.5 150.6 33.5H137.4Z"
          fill="currentColor"
        />
        <path
          d="M156 43.7H148.664L148.339 44.5123C147.629 46.2866 145.911 47.45 144 47.45C142.089 47.45 140.371 46.2866 139.661 44.5123L139.336 43.7H132V51.8C132 53.2912 133.209 54.5 134.7 54.5H153.3C154.791 54.5 156 53.2911 156 51.8V43.7Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5" clipPath={`url(#${gradientIds[5]})`}>
        <path
          d="M138.574 95.4256C137.77 94.6214 137.77 93.3175 138.574 92.5133C139.379 91.7091 140.682 91.7091 141.487 92.5133L145.458 96.4846C146.262 97.2888 146.262 98.5927 145.458 99.3969C144.654 100.201 143.35 100.201 142.546 99.3969L138.574 95.4256Z"
          fill="currentColor"
        />
        <path
          d="M142.542 83.5154C141.738 82.7112 141.738 81.4073 142.542 80.6031C143.346 79.799 144.65 79.799 145.454 80.6031L149.426 84.5744C150.23 85.3786 150.23 86.6825 149.426 87.4867C148.621 88.2909 147.318 88.2909 146.513 87.4867L142.542 83.5154Z"
          fill="currentColor"
        />
        <path
          d="M134.603 91.4543C133.799 90.6501 133.799 89.3463 134.603 88.5421C135.407 87.7379 136.711 87.7379 137.515 88.5421C138.32 89.3463 138.32 90.6501 137.515 91.4543C136.711 92.2585 135.407 92.2585 134.603 91.4543Z"
          fill="currentColor"
        />
        <path
          d="M150.485 91.4579C149.68 90.6537 149.68 89.3499 150.485 88.5457C151.289 87.7415 152.593 87.7415 153.397 88.5457C154.201 89.3499 154.201 90.6537 153.397 91.4579C152.593 92.2621 151.289 92.2621 150.485 91.4579Z"
          fill="currentColor"
        />
        <path
          d="M138.574 87.4831C137.77 86.6789 137.77 85.375 138.574 84.5708C139.379 83.7666 140.682 83.7666 141.487 84.5708L149.429 92.5133C150.233 93.3175 150.233 94.6214 149.429 95.4256C148.625 96.2298 147.321 96.2298 146.517 95.4256L138.574 87.4831Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M142.65 126.35C142.65 125.604 143.254 125 144 125C144.746 125 145.35 125.604 145.35 126.35V137.65C145.35 138.396 144.746 139 144 139C143.254 139 142.65 138.396 142.65 137.65V126.35ZM148.05 129.35C148.05 128.604 148.654 128 149.4 128C150.146 128 150.75 128.604 150.75 129.35V137.65C150.75 138.396 150.146 139 149.4 139C148.654 139 148.05 138.396 148.05 137.65V129.35ZM138.6 131C137.854 131 137.25 131.604 137.25 132.35V137.65C137.25 138.396 137.854 139 138.6 139C139.346 139 139.95 138.396 139.95 137.65V132.35C139.95 131.604 139.346 131 138.6 131Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M138.75 170.75C138.75 169.921 139.422 169.25 140.25 169.25H140.657C140.966 169.25 141.243 169.06 141.356 168.772L143.074 164.364C143.159 164.145 143.371 164 143.606 164C144.238 164 144.75 164.512 144.75 165.144V167C144.75 167.414 145.086 167.75 145.5 167.75H146.251C147.614 167.75 148.664 168.953 148.48 170.303L148.071 173.307C147.919 174.422 146.966 175.253 145.841 175.253H140.25C139.422 175.253 138.75 174.582 138.75 173.753V170.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M185.975 15.5C185.975 14.3954 186.87 13.5 187.975 13.5H188.025C189.13 13.5 190.025 14.3954 190.025 15.5V32.5C190.025 33.6046 189.13 34.5 188.025 34.5H187.975C186.87 34.5 185.975 33.6046 185.975 32.5V15.5ZM194.075 20C194.075 18.8954 194.971 18 196.075 18H196.125C197.23 18 198.125 18.8954 198.125 20V32.5C198.125 33.6046 197.23 34.5 196.125 34.5H196.075C194.971 34.5 194.075 33.6046 194.075 32.5V20ZM179.875 22.5C178.77 22.5 177.875 23.3954 177.875 24.5V32.5C177.875 33.6046 178.77 34.5 179.875 34.5H179.925C181.03 34.5 181.925 33.6046 181.925 32.5V24.5C181.925 23.3954 181.03 22.5 179.925 22.5H179.875Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M179.25 71.2493C179.25 69.8686 180.369 68.7493 181.75 68.7493H182.428C182.943 68.7493 183.406 68.4332 183.593 67.9532L186.456 60.607C186.599 60.241 186.951 60 187.344 60C188.397 60 189.25 60.8534 189.25 61.9061V64.9992C189.25 65.6896 189.81 66.2492 190.5 66.2492H191.751C194.024 66.2492 195.774 68.2542 195.467 70.5058L194.784 75.5119C194.531 77.37 192.944 78.7553 191.069 78.7553H181.75C180.369 78.7553 179.25 77.6361 179.25 76.2553V71.2493Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M189.416 105.148C189.565 104.11 188.221 103.572 187.612 104.424L181.189 113.419C180.716 114.081 181.19 115 182.003 115L187.138 115L186.585 118.852C186.436 119.89 187.779 120.429 188.388 119.576L194.811 110.582C195.284 109.92 194.81 109.001 193.997 109.001L188.862 109.001L189.416 105.148Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[6]})`}>
        <path
          d="M182.75 149.254C182.75 150.082 183.422 150.754 184.25 150.754H184.657C184.966 150.754 185.243 150.943 185.356 151.231L187.074 155.639C187.159 155.859 187.371 156.003 187.606 156.003C188.238 156.003 188.75 155.491 188.75 154.86V153.004C188.75 152.589 189.086 152.254 189.5 152.254H190.248C191.611 152.254 192.661 151.051 192.477 149.7L192.067 146.696C191.915 145.581 190.963 144.75 189.838 144.75H184.25C183.422 144.75 182.75 145.422 182.75 146.25V149.254Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M222.562 35.8646C224.807 33.6114 228.388 33.4298 230.848 35.4443L232 36.3874L233.152 35.4444C235.612 33.4299 239.193 33.6115 241.438 35.8647C243.746 38.181 243.86 41.8914 241.718 44.3446L241.732 44.3557L233.664 53.2184C232.771 54.1989 231.229 54.1989 230.336 53.2184L222.268 44.3558L222.282 44.3447C220.14 41.8914 220.254 38.181 222.562 35.8646Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M230.313 82.9375C230.313 82.0055 231.068 81.25 232 81.25C232.932 81.25 233.688 82.0055 233.688 82.9375V97.0625C233.688 97.9945 232.932 98.75 232 98.75C231.068 98.75 230.313 97.9945 230.313 97.0625V82.9375ZM237.063 86.6875C237.063 85.7555 237.818 85 238.75 85C239.682 85 240.438 85.7555 240.438 86.6875V97.0625C240.438 97.9945 239.682 98.75 238.75 98.75C237.818 98.75 237.063 97.9945 237.063 97.0625V86.6875ZM225.25 88.75C224.318 88.75 223.562 89.5055 223.562 90.4375V97.0625C223.562 97.9945 224.318 98.75 225.25 98.75C226.182 98.75 226.938 97.9945 226.938 97.0625V90.4375C226.938 89.5055 226.182 88.75 225.25 88.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M225 132.999C225 131.895 225.895 130.999 227 130.999H227.542C227.955 130.999 228.324 130.747 228.474 130.363L230.765 124.486C230.879 124.193 231.161 124 231.475 124C232.317 124 233 124.683 233 125.525V127.999C233 128.552 233.448 128.999 234 128.999H235.001C236.819 128.999 238.219 130.603 237.974 132.405L237.428 136.41C237.225 137.896 235.955 139.004 234.455 139.004H227C225.895 139.004 225 138.109 225 137.004V132.999Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[7]})`}>
        <path
          d="M233.062 164.861C233.173 164.083 232.166 163.679 231.709 164.318L226.892 171.064C226.537 171.561 226.892 172.25 227.502 172.25L231.354 172.25L230.939 175.139C230.827 175.917 231.835 176.321 232.291 175.682L237.108 168.937C237.463 168.44 237.108 167.751 236.498 167.751L232.646 167.751L233.062 164.861Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6" clipPath={`url(#${gradientIds[8]})`}>
        <path
          d="M269.489 30.5107C268.524 29.5457 268.524 27.981 269.489 27.016C270.454 26.051 272.019 26.051 272.984 27.016L277.75 31.7815C278.715 32.7465 278.715 34.3112 277.75 35.2762C276.784 36.2413 275.22 36.2413 274.255 35.2762L269.489 30.5107Z"
          fill="currentColor"
        />
        <path
          d="M274.25 16.2185C273.285 15.2535 273.285 13.6888 274.25 12.7238C275.216 11.7587 276.78 11.7587 277.745 12.7238L282.511 17.4893C283.476 18.4543 283.476 20.019 282.511 20.984C281.546 21.949 279.981 21.949 279.016 20.984L274.25 16.2185Z"
          fill="currentColor"
        />
        <path
          d="M264.724 25.7452C263.759 24.7802 263.759 23.2155 264.724 22.2505C265.689 21.2854 267.253 21.2854 268.218 22.2505C269.184 23.2155 269.184 24.7802 268.218 25.7452C267.253 26.7102 265.689 26.7102 264.724 25.7452Z"
          fill="currentColor"
        />
        <path
          d="M283.782 25.7495C282.816 24.7845 282.816 23.2198 283.782 22.2548C284.747 21.2898 286.311 21.2898 287.276 22.2548C288.241 23.2198 288.241 24.7845 287.276 25.7495C286.311 26.7146 284.747 26.7146 283.782 25.7495Z"
          fill="currentColor"
        />
        <path
          d="M269.489 20.9797C268.524 20.0146 268.524 18.45 269.489 17.485C270.454 16.5199 272.019 16.5199 272.984 17.485L282.515 27.016C283.48 27.981 283.48 29.5457 282.515 30.5107C281.55 31.4757 279.985 31.4757 279.02 30.5107L269.489 20.9797Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M270.5 61.25C268.015 61.25 266 63.2647 266 65.75V67.5H272.198C273.067 67.5 273.848 68.029 274.171 68.8358L274.473 69.5913C274.723 70.2156 275.328 70.625 276 70.625C276.672 70.625 277.277 70.2156 277.527 69.5913L277.829 68.8358C278.152 68.029 278.933 67.5 279.802 67.5H286V65.75C286 63.2647 283.985 61.25 281.5 61.25H270.5Z"
          fill="currentColor"
        />
        <path
          d="M286 69.75H279.887L279.616 70.4269C279.024 71.9055 277.592 72.875 276 72.875C274.408 72.875 272.976 71.9055 272.384 70.4269L272.113 69.75H266V76.5C266 77.7426 267.007 78.75 268.25 78.75H283.75C284.993 78.75 286 77.7426 286 76.5V69.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4" clipPath={`url(#${gradientIds[9]})`}>
        <path
          d="M271.66 116.34C271.016 115.697 271.016 114.654 271.66 114.011C272.303 113.367 273.346 113.367 273.989 114.011L277.166 117.188C277.81 117.831 277.81 118.874 277.166 119.517C276.523 120.161 275.48 120.161 274.837 119.517L271.66 116.34Z"
          fill="currentColor"
        />
        <path
          d="M274.834 106.812C274.19 106.169 274.19 105.126 274.834 104.483C275.477 103.839 276.52 103.839 277.163 104.483L280.34 107.66C280.984 108.303 280.984 109.346 280.34 109.989C279.697 110.633 278.654 110.633 278.011 109.989L274.834 106.812Z"
          fill="currentColor"
        />
        <path
          d="M268.483 113.163C267.839 112.52 267.839 111.477 268.483 110.834C269.126 110.19 270.169 110.19 270.812 110.834C271.456 111.477 271.456 112.52 270.812 113.163C270.169 113.807 269.126 113.807 268.483 113.163Z"
          fill="currentColor"
        />
        <path
          d="M281.188 113.166C280.544 112.523 280.544 111.48 281.188 110.837C281.831 110.193 282.874 110.193 283.517 110.837C284.161 111.48 284.161 112.523 283.517 113.166C282.874 113.81 281.831 113.81 281.188 113.166Z"
          fill="currentColor"
        />
        <path
          d="M271.66 109.986C271.016 109.343 271.016 108.3 271.66 107.657C272.303 107.013 273.346 107.013 273.989 107.657L280.343 114.011C280.987 114.654 280.987 115.697 280.343 116.34C279.7 116.984 278.657 116.984 278.014 116.34L271.66 109.986Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[10]})`}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M274.988 145.763C274.988 145.203 275.441 144.75 276 144.75C276.559 144.75 277.013 145.203 277.013 145.763V154.237C277.013 154.797 276.559 155.25 276 155.25C275.441 155.25 274.988 154.797 274.988 154.238V145.763ZM279.038 148.012C279.038 147.453 279.491 147 280.05 147C280.609 147 281.063 147.453 281.063 148.012V154.238C281.063 154.797 280.609 155.25 280.05 155.25C279.491 155.25 279.038 154.797 279.038 154.238V148.012ZM271.95 149.25C271.391 149.25 270.938 149.703 270.938 150.263V154.237C270.938 154.797 271.391 155.25 271.95 155.25C272.509 155.25 272.963 154.797 272.963 154.237V150.263C272.963 149.703 272.509 149.25 271.95 149.25Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M317.975 35.5C317.975 34.3954 318.87 33.5 319.975 33.5H320.025C321.13 33.5 322.025 34.3954 322.025 35.5V52.5C322.025 53.6046 321.13 54.5 320.025 54.5H319.975C318.87 54.5 317.975 53.6046 317.975 52.5V35.5ZM326.075 40C326.075 38.8954 326.971 38 328.075 38H328.125C329.23 38 330.125 38.8954 330.125 40V52.5C330.125 53.6046 329.23 54.5 328.125 54.5H328.075C326.971 54.5 326.075 53.6046 326.075 52.5V40ZM311.875 42.5C310.77 42.5 309.875 43.3954 309.875 44.5V52.5C309.875 53.6046 310.77 54.5 311.875 54.5H311.925C313.03 54.5 313.925 53.6046 313.925 52.5V44.5C313.925 43.3954 313.03 42.5 311.925 42.5H311.875Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M327.5 82.9931C327.5 84.2469 326.873 85.4178 325.83 86.1132L321.875 88.75C321.856 88.7595 321.843 88.7694 321.829 88.7797C321.819 88.7873 321.808 88.7951 321.795 88.8031L321.704 88.8642C321.353 89.1184 321.125 89.5314 321.125 89.9976L321.124 89.9988L321.125 90C321.125 90.4662 321.353 90.8792 321.704 91.1334L321.795 91.1944C321.808 91.2024 321.819 91.2102 321.829 91.2178C321.843 91.2281 321.856 91.238 321.875 91.2476L325.83 93.8843C326.873 94.5798 327.5 95.7507 327.5 97.0045V97.4976C327.5 98.8783 326.381 99.9976 325 99.9976L315 99.9976C313.619 99.9976 312.5 98.8783 312.5 97.4976V97.0045C312.5 95.7507 313.127 94.5798 314.17 93.8843L318.125 91.2476C318.144 91.238 318.157 91.2281 318.171 91.2178C318.181 91.2102 318.192 91.2024 318.205 91.1945L318.296 91.1334C318.647 90.8792 318.875 90.4662 318.875 90L318.876 89.9988L318.875 89.9976C318.875 89.5313 318.647 89.1184 318.296 88.8642L318.205 88.8031C318.192 88.7951 318.181 88.7874 318.171 88.7798C318.157 88.7695 318.144 88.7595 318.125 88.75L314.17 86.1133C313.127 85.4178 312.5 84.2469 312.5 82.9931V82.5C312.5 81.1193 313.619 80 315 80H325C326.381 80 327.5 81.1193 327.5 82.5V82.9931ZM324.194 84.5L324.582 84.2411C324.999 83.9629 325.25 83.4946 325.25 82.9931V82.5C325.25 82.3619 325.138 82.25 325 82.25L315 82.25C314.862 82.25 314.75 82.3619 314.75 82.5V82.9931C314.75 83.4946 315.001 83.9629 315.418 84.2411L315.806 84.5H324.194ZM324.141 95.4625H315.859L320 92.7017L324.141 95.4625Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M315.6 125C313.612 125 312 126.612 312 128.6V130H316.958C317.654 130 318.279 130.423 318.537 131.069L318.779 131.673C318.978 132.172 319.462 132.5 320 132.5C320.538 132.5 321.022 132.172 321.221 131.673L321.463 131.069C321.721 130.423 322.346 130 323.042 130H328V128.6C328 126.612 326.388 125 324.4 125H315.6Z"
          fill="currentColor"
        />
        <path
          d="M328 131.8H323.109L322.893 132.342C322.42 133.524 321.274 134.3 320 134.3C318.726 134.3 317.58 133.524 317.107 132.342L316.891 131.8H312V137.2C312 138.194 312.806 139 313.8 139H326.2C327.194 139 328 138.194 328 137.2V131.8Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[11]})`}>
        <path
          d="M316.745 173.255C316.262 172.773 316.262 171.991 316.745 171.508C317.227 171.025 318.009 171.025 318.492 171.508L320.875 173.891C321.357 174.373 321.357 175.156 320.875 175.638C320.392 176.121 319.61 176.121 319.127 175.638L316.745 173.255Z"
          fill="currentColor"
        />
        <path
          d="M319.125 166.109C318.643 165.627 318.643 164.844 319.125 164.362C319.608 163.879 320.39 163.879 320.873 164.362L323.255 166.745C323.738 167.227 323.738 168.009 323.255 168.492C322.773 168.975 321.991 168.975 321.508 168.492L319.125 166.109Z"
          fill="currentColor"
        />
        <path
          d="M314.362 170.873C313.879 170.39 313.879 169.608 314.362 169.125C314.844 168.643 315.627 168.643 316.109 169.125C316.592 169.608 316.592 170.39 316.109 170.873C315.627 171.355 314.844 171.355 314.362 170.873Z"
          fill="currentColor"
        />
        <path
          d="M323.891 170.875C323.408 170.392 323.408 169.61 323.891 169.127C324.373 168.645 325.156 168.645 325.638 169.127C326.121 169.61 326.121 170.392 325.638 170.875C325.156 171.357 324.373 171.357 323.891 170.875Z"
          fill="currentColor"
        />
        <path
          d="M316.745 168.49C316.262 168.007 316.262 167.225 316.745 166.742C317.227 166.26 318.009 166.26 318.492 166.742L323.258 171.508C323.74 171.991 323.74 172.773 323.258 173.255C322.775 173.738 321.993 173.738 321.51 173.255L316.745 168.49Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M353.5 25.4992C353.5 23.8423 354.843 22.4992 356.5 22.4992H357.314C357.932 22.4992 358.487 22.1199 358.711 21.5439L362.147 12.7284C362.318 12.2892 362.741 12 363.213 12C364.476 12 365.5 13.0241 365.5 14.2873V17.9991C365.5 18.8275 366.172 19.4991 367 19.4991H368.502C371.229 19.4991 373.329 21.9051 372.96 24.607L372.141 30.6143C371.837 32.844 369.933 34.5064 367.683 34.5064H356.5C354.843 34.5064 353.5 33.1633 353.5 31.5064V25.4992Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M365.77 61.4345C365.956 60.1381 364.276 59.4645 363.515 60.5303L355.486 71.7737C354.896 72.601 355.487 73.7501 356.504 73.7501L362.923 73.7501L362.231 78.5656C362.045 79.862 363.724 80.5357 364.485 79.4698L372.514 68.2277C373.104 67.4003 372.513 66.2513 371.496 66.2513L365.077 66.2512L365.77 61.4345Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4" clipPath={`url(#${gradientIds[12]})`}>
        <path
          d="M357 111.005C357 112.109 357.895 113.005 359 113.005H359.542C359.955 113.005 360.324 113.258 360.474 113.642L362.765 119.519C362.879 119.811 363.161 120.004 363.475 120.004C364.317 120.004 365 119.322 365 118.479V116.005C365 115.453 365.448 115.005 366 115.005H366.997C368.815 115.005 370.215 113.401 369.969 111.6L369.423 107.595C369.221 106.108 367.951 105 366.451 105H359C357.895 105 357 105.895 357 107V111.005Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M368.5 145.796C368.5 146.548 368.124 147.251 367.498 147.668L365.125 149.25C365.114 149.256 365.106 149.262 365.097 149.268C365.091 149.272 365.085 149.277 365.077 149.282L365.022 149.319C364.812 149.471 364.675 149.719 364.675 149.999L364.675 149.999L364.675 150C364.675 150.28 364.812 150.528 365.022 150.68L365.077 150.717C365.085 150.721 365.091 150.726 365.097 150.731C365.106 150.737 365.114 150.743 365.125 150.749L367.498 152.331C368.124 152.748 368.5 153.45 368.5 154.203V154.499C368.5 155.327 367.828 155.999 367 155.999L361 155.999C360.172 155.999 359.5 155.327 359.5 154.499V154.203C359.5 153.45 359.876 152.748 360.502 152.331L362.875 150.749C362.886 150.743 362.894 150.737 362.903 150.731C362.909 150.726 362.915 150.721 362.923 150.717L362.978 150.68C363.188 150.527 363.325 150.28 363.325 150L363.325 149.999L363.325 149.999C363.325 149.719 363.188 149.471 362.978 149.319L362.923 149.282C362.915 149.277 362.909 149.272 362.903 149.268C362.894 149.262 362.886 149.256 362.875 149.25L360.502 147.668C359.876 147.251 359.5 146.548 359.5 145.796V145.5C359.5 144.672 360.172 144 361 144H367C367.828 144 368.5 144.672 368.5 145.5V145.796ZM366.516 146.7L366.749 146.545C367 146.378 367.15 146.097 367.15 145.796V145.5C367.15 145.417 367.083 145.35 367 145.35L361 145.35C360.917 145.35 360.85 145.417 360.85 145.5V145.796C360.85 146.097 361 146.378 361.251 146.545L361.484 146.7H366.516ZM366.485 153.277H361.515L364 151.621L366.485 153.277Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6" clipPath={`url(#${gradientIds[13]})`}>
        <path
          d="M401.489 50.5107C400.524 49.5457 400.524 47.981 401.489 47.016C402.454 46.051 404.019 46.051 404.984 47.016L409.75 51.7815C410.715 52.7465 410.715 54.3112 409.75 55.2762C408.784 56.2413 407.22 56.2413 406.255 55.2762L401.489 50.5107Z"
          fill="currentColor"
        />
        <path
          d="M406.25 36.2185C405.285 35.2535 405.285 33.6888 406.25 32.7238C407.216 31.7587 408.78 31.7587 409.745 32.7238L414.511 37.4893C415.476 38.4543 415.476 40.019 414.511 40.984C413.546 41.949 411.981 41.949 411.016 40.984L406.25 36.2185Z"
          fill="currentColor"
        />
        <path
          d="M396.724 45.7452C395.759 44.7802 395.759 43.2155 396.724 42.2505C397.689 41.2854 399.253 41.2854 400.218 42.2505C401.184 43.2155 401.184 44.7802 400.218 45.7452C399.253 46.7102 397.689 46.7102 396.724 45.7452Z"
          fill="currentColor"
        />
        <path
          d="M415.782 45.7495C414.816 44.7845 414.816 43.2198 415.782 42.2548C416.747 41.2898 418.311 41.2898 419.276 42.2548C420.241 43.2198 420.241 44.7845 419.276 45.7495C418.311 46.7146 416.747 46.7146 415.782 45.7495Z"
          fill="currentColor"
        />
        <path
          d="M401.489 40.9797C400.524 40.0146 400.524 38.45 401.489 37.485C402.454 36.5199 404.019 36.5199 404.984 37.485L414.515 47.016C415.48 47.981 415.48 49.5457 414.515 50.5107C413.55 51.4757 411.985 51.4757 411.02 50.5107L401.489 40.9797Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M406.313 82.9375C406.313 82.0055 407.068 81.25 408 81.25C408.932 81.25 409.688 82.0055 409.688 82.9375V97.0625C409.688 97.9945 408.932 98.75 408 98.75C407.068 98.75 406.313 97.9945 406.313 97.0625V82.9375ZM413.063 86.6875C413.063 85.7555 413.818 85 414.75 85C415.682 85 416.438 85.7555 416.438 86.6875V97.0625C416.438 97.9945 415.682 98.75 414.75 98.75C413.818 98.75 413.063 97.9945 413.063 97.0625V86.6875ZM401.25 88.75C400.318 88.75 399.562 89.5055 399.562 90.4375V97.0625C399.562 97.9945 400.318 98.75 401.25 98.75C402.182 98.75 402.938 97.9945 402.938 97.0625V90.4375C402.938 89.5055 402.182 88.75 401.25 88.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M401 132.999C401 131.895 401.895 130.999 403 130.999H403.542C403.955 130.999 404.324 130.747 404.474 130.363L406.765 124.486C406.879 124.193 407.161 124 407.475 124C408.317 124 409 124.683 409 125.525V127.999C409 128.552 409.448 128.999 410 128.999H411.001C412.819 128.999 414.219 130.603 413.974 132.405L413.428 136.41C413.225 137.896 411.955 139.004 410.455 139.004H403C401.895 139.004 401 138.109 401 137.004V132.999Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[14]})`}>
        <path
          d="M409.062 164.861C409.174 164.083 408.166 163.679 407.709 164.318L402.892 171.064C402.537 171.561 402.892 172.25 403.502 172.25L407.354 172.25L406.939 175.139C406.827 175.917 407.835 176.321 408.291 175.682L413.108 168.937C413.463 168.44 413.108 167.751 412.498 167.751L408.647 167.751L409.062 164.861Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M449.975 15.5C449.975 14.3954 450.87 13.5 451.975 13.5H452.025C453.13 13.5 454.025 14.3954 454.025 15.5V32.5C454.025 33.6046 453.13 34.5 452.025 34.5H451.975C450.87 34.5 449.975 33.6046 449.975 32.5V15.5ZM458.075 20C458.075 18.8954 458.971 18 460.075 18H460.125C461.23 18 462.125 18.8954 462.125 20V32.5C462.125 33.6046 461.23 34.5 460.125 34.5H460.075C458.971 34.5 458.075 33.6046 458.075 32.5V20ZM443.875 22.5C442.77 22.5 441.875 23.3954 441.875 24.5V32.5C441.875 33.6046 442.77 34.5 443.875 34.5H443.925C445.03 34.5 445.925 33.6046 445.925 32.5V24.5C445.925 23.3954 445.03 22.5 443.925 22.5H443.875Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M443.25 71.2493C443.25 69.8686 444.369 68.7493 445.75 68.7493H446.428C446.943 68.7493 447.406 68.4332 447.593 67.9532L450.456 60.607C450.599 60.241 450.951 60 451.344 60C452.397 60 453.25 60.8534 453.25 61.9061V64.9992C453.25 65.6896 453.81 66.2492 454.5 66.2492H455.751C458.024 66.2492 459.774 68.2542 459.467 70.5058L458.784 75.5119C458.531 77.37 456.944 78.7553 455.069 78.7553H445.75C444.369 78.7553 443.25 77.6361 443.25 76.2553V71.2493Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M453.416 105.148C453.565 104.11 452.221 103.572 451.612 104.424L445.189 113.419C444.716 114.081 445.19 115 446.003 115L451.138 115L450.585 118.852C450.436 119.89 451.779 120.429 452.388 119.576L458.811 110.582C459.284 109.92 458.81 109.001 457.997 109.001L452.862 109.001L453.416 105.148Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[15]})`}>
        <path
          d="M446.75 149.254C446.75 150.082 447.422 150.754 448.25 150.754H448.657C448.966 150.754 449.243 150.943 449.356 151.231L451.074 155.639C451.159 155.859 451.371 156.003 451.606 156.003C452.238 156.003 452.75 155.491 452.75 154.86V153.004C452.75 152.589 453.086 152.254 453.5 152.254H454.248C455.611 152.254 456.661 151.051 456.477 149.7L456.067 146.696C455.915 145.581 454.963 144.75 453.838 144.75H448.25C447.422 144.75 446.75 145.422 446.75 146.25V149.254Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M489.4 33.5C486.418 33.5 484 35.9177 484 38.9V41H491.438C492.48 41 493.418 41.6348 493.805 42.603L494.168 43.5095C494.467 44.2587 495.193 44.75 496 44.75C496.807 44.75 497.533 44.2587 497.832 43.5096L498.195 42.603C498.582 41.6348 499.52 41 500.562 41H508V38.9C508 35.9177 505.582 33.5 502.6 33.5H489.4Z"
          fill="currentColor"
        />
        <path
          d="M508 43.7H500.664L500.339 44.5123C499.629 46.2866 497.911 47.45 496 47.45C494.089 47.45 492.371 46.2866 491.661 44.5123L491.336 43.7H484V51.8C484 53.2912 485.209 54.5 486.7 54.5H505.3C506.791 54.5 508 53.2911 508 51.8V43.7Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5" clipPath={`url(#${gradientIds[16]})`}>
        <path
          d="M490.574 95.4256C489.77 94.6214 489.77 93.3175 490.574 92.5133C491.379 91.7091 492.682 91.7091 493.487 92.5133L497.458 96.4846C498.262 97.2888 498.262 98.5927 497.458 99.3969C496.654 100.201 495.35 100.201 494.546 99.3969L490.574 95.4256Z"
          fill="currentColor"
        />
        <path
          d="M494.542 83.5154C493.738 82.7112 493.738 81.4073 494.542 80.6031C495.346 79.799 496.65 79.799 497.454 80.6031L501.426 84.5744C502.23 85.3786 502.23 86.6825 501.426 87.4867C500.621 88.2909 499.318 88.2909 498.513 87.4867L494.542 83.5154Z"
          fill="currentColor"
        />
        <path
          d="M486.603 91.4543C485.799 90.6501 485.799 89.3463 486.603 88.5421C487.407 87.7379 488.711 87.7379 489.515 88.5421C490.32 89.3463 490.32 90.6501 489.515 91.4543C488.711 92.2585 487.407 92.2585 486.603 91.4543Z"
          fill="currentColor"
        />
        <path
          d="M502.485 91.4579C501.68 90.6537 501.68 89.3499 502.485 88.5457C503.289 87.7415 504.593 87.7415 505.397 88.5457C506.201 89.3499 506.201 90.6537 505.397 91.4579C504.593 92.2621 503.289 92.2621 502.485 91.4579Z"
          fill="currentColor"
        />
        <path
          d="M490.574 87.4831C489.77 86.6789 489.77 85.375 490.574 84.5708C491.379 83.7666 492.682 83.7666 493.487 84.5708L501.429 92.5133C502.233 93.3175 502.233 94.6214 501.429 95.4256C500.625 96.2298 499.321 96.2298 498.517 95.4256L490.574 87.4831Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M494.65 126.35C494.65 125.604 495.254 125 496 125C496.746 125 497.35 125.604 497.35 126.35V137.65C497.35 138.396 496.746 139 496 139C495.254 139 494.65 138.396 494.65 137.65V126.35ZM500.05 129.35C500.05 128.604 500.654 128 501.4 128C502.146 128 502.75 128.604 502.75 129.35V137.65C502.75 138.396 502.146 139 501.4 139C500.654 139 500.05 138.396 500.05 137.65V129.35ZM490.6 131C489.854 131 489.25 131.604 489.25 132.35V137.65C489.25 138.396 489.854 139 490.6 139C491.346 139 491.95 138.396 491.95 137.65V132.35C491.95 131.604 491.346 131 490.6 131Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M490.75 170.75C490.75 169.921 491.422 169.25 492.25 169.25H492.657C492.966 169.25 493.243 169.06 493.356 168.772L495.074 164.364C495.159 164.145 495.371 164 495.606 164C496.238 164 496.75 164.512 496.75 165.144V167C496.75 167.414 497.086 167.75 497.5 167.75H498.251C499.614 167.75 500.664 168.953 500.48 170.303L500.071 173.307C499.919 174.422 498.966 175.253 497.841 175.253H492.25C491.422 175.253 490.75 174.582 490.75 173.753V170.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M542.123 13.7214C542.347 12.1657 540.331 11.3574 539.418 12.6364L529.784 26.1284C529.075 27.1212 529.784 28.5001 531.004 28.5001L538.707 28.5002L537.877 34.2787C537.654 35.8344 539.669 36.6428 540.583 35.3638L550.216 21.8732C550.925 20.8804 550.216 19.5015 548.996 19.5015L541.293 19.5014L542.123 13.7214Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M534.5 61.25C532.015 61.25 530 63.2647 530 65.75V67.5H536.198C537.067 67.5 537.848 68.029 538.171 68.8358L538.473 69.5913C538.723 70.2156 539.328 70.625 540 70.625C540.672 70.625 541.277 70.2156 541.527 69.5913L541.829 68.8358C542.152 68.029 542.933 67.5 543.802 67.5H550V65.75C550 63.2647 547.985 61.25 545.5 61.25H534.5Z"
          fill="currentColor"
        />
        <path
          d="M550 69.75H543.887L543.616 70.4269C543.024 71.9055 541.592 72.875 540 72.875C538.408 72.875 536.976 71.9055 536.384 70.4269L536.113 69.75H530V76.5C530 77.7426 531.007 78.75 532.25 78.75H547.75C548.993 78.75 550 77.7426 550 76.5V69.75Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.4" clipPath={`url(#${gradientIds[17]})`}>
        <path
          d="M535.66 116.34C535.016 115.697 535.016 114.654 535.66 114.011C536.303 113.367 537.346 113.367 537.989 114.011L541.166 117.188C541.81 117.831 541.81 118.874 541.166 119.517C540.523 120.161 539.48 120.161 538.837 119.517L535.66 116.34Z"
          fill="currentColor"
        />
        <path
          d="M538.834 106.812C538.19 106.169 538.19 105.126 538.834 104.483C539.477 103.839 540.52 103.839 541.163 104.483L544.34 107.66C544.984 108.303 544.984 109.346 544.34 109.989C543.697 110.633 542.654 110.633 542.011 109.989L538.834 106.812Z"
          fill="currentColor"
        />
        <path
          d="M532.483 113.163C531.839 112.52 531.839 111.477 532.483 110.834C533.126 110.19 534.169 110.19 534.812 110.834C535.456 111.477 535.456 112.52 534.812 113.163C534.169 113.807 533.126 113.807 532.483 113.163Z"
          fill="currentColor"
        />
        <path
          d="M545.188 113.166C544.544 112.523 544.544 111.48 545.188 110.837C545.831 110.193 546.874 110.193 547.517 110.837C548.161 111.48 548.161 112.523 547.517 113.166C546.874 113.81 545.831 113.81 545.188 113.166Z"
          fill="currentColor"
        />
        <path
          d="M535.66 109.986C535.016 109.343 535.016 108.3 535.66 107.657C536.303 107.013 537.346 107.013 537.989 107.657L544.343 114.011C544.987 114.654 544.987 115.697 544.343 116.34C543.7 116.984 542.657 116.984 542.014 116.34L535.66 109.986Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.3" clipPath={`url(#${gradientIds[18]})`}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M538.988 145.763C538.988 145.203 539.441 144.75 540 144.75C540.559 144.75 541.013 145.203 541.013 145.763V154.237C541.013 154.797 540.559 155.25 540 155.25C539.441 155.25 538.988 154.797 538.988 154.238V145.763ZM543.038 148.012C543.038 147.453 543.491 147 544.05 147C544.609 147 545.063 147.453 545.063 148.012V154.238C545.063 154.797 544.609 155.25 544.05 155.25C543.491 155.25 543.038 154.797 543.038 154.238V148.012ZM535.95 149.25C535.391 149.25 534.938 149.703 534.938 150.263V154.237C534.938 154.797 535.391 155.25 535.95 155.25C536.509 155.25 536.963 154.797 536.963 154.237V150.263C536.963 149.703 536.509 149.25 535.95 149.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={gradientIds[0]}>
          <rect width="24" height="24" fill="white" transform="translate(0 12)" />
        </clipPath>
        <clipPath id={gradientIds[1]}>
          <rect width="20" height="20" fill="white" transform="translate(2 60)" />
        </clipPath>
        <clipPath id={gradientIds[2]}>
          <rect width="12" height="12" fill="white" transform="translate(50 164)" />
        </clipPath>
        <clipPath id={gradientIds[3]}>
          <rect width="16" height="16" fill="white" transform="translate(92 104)" />
        </clipPath>
        <clipPath id={gradientIds[4]}>
          <rect width="24" height="24" fill="white" transform="translate(132 32)" />
        </clipPath>
        <clipPath id={gradientIds[5]}>
          <rect width="20" height="20" fill="white" transform="translate(134 80)" />
        </clipPath>
        <clipPath id={gradientIds[6]}>
          <rect width="12" height="12" fill="white" transform="translate(182 144)" />
        </clipPath>
        <clipPath id={gradientIds[7]}>
          <rect width="12" height="12" fill="white" transform="translate(226 164)" />
        </clipPath>
        <clipPath id={gradientIds[8]}>
          <rect width="24" height="24" fill="white" transform="translate(264 12)" />
        </clipPath>
        <clipPath id={gradientIds[9]}>
          <rect width="16" height="16" fill="white" transform="translate(268 104)" />
        </clipPath>
        <clipPath id={gradientIds[10]}>
          <rect width="12" height="12" fill="white" transform="translate(270 144)" />
        </clipPath>
        <clipPath id={gradientIds[11]}>
          <rect width="12" height="12" fill="white" transform="translate(314 164)" />
        </clipPath>
        <clipPath id={gradientIds[12]}>
          <rect width="16" height="16" fill="white" transform="translate(356 104)" />
        </clipPath>
        <clipPath id={gradientIds[13]}>
          <rect width="24" height="24" fill="white" transform="translate(396 32)" />
        </clipPath>
        <clipPath id={gradientIds[14]}>
          <rect width="12" height="12" fill="white" transform="translate(402 164)" />
        </clipPath>
        <clipPath id={gradientIds[15]}>
          <rect width="12" height="12" fill="white" transform="translate(446 144)" />
        </clipPath>
        <clipPath id={gradientIds[16]}>
          <rect width="20" height="20" fill="white" transform="translate(486 80)" />
        </clipPath>
        <clipPath id={gradientIds[17]}>
          <rect width="16" height="16" fill="white" transform="translate(532 104)" />
        </clipPath>
        <clipPath id={gradientIds[18]}>
          <rect width="12" height="12" fill="white" transform="translate(534 144)" />
        </clipPath>
      </defs>
    </svg>
  );
};
